import React, { Component, useState, useEffect } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import "./categories.css";

function Category({ item, toggleFilter }) {
  const [open, setOpen] = useState(false);
  let activeStyle = {
    fontWeight: "bold",
  };
  const handleClick = (isActive) => {
    if (isActive) setOpen(true);
    if (isMobile) toggleFilter();
  };
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 850;
  const generateCategoriesTree = (item) => {
    if (item.children.length > 0) {
      return (
        <li>
          <div className={open ? "category open" : "category"}>
            <div className="category-title">
              <NavLink
                to={`/user/shop/${item._id}`}
                // onClick={({ isActive }) =>
                //   isActive ? () => setOpen(true) : undefined
                // }
                onClick={({ isActive }) => handleClick(isActive)}
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
              >
                {item.name}
              </NavLink>

              <i onClick={() => setOpen(!open)}>
                <AiOutlineArrowDown />
              </i>
            </div>
            <div className="sous-category">
              {item.children.map((child, index) => (
                <NavLink
                  to={`/user/shop/${child._id}`}
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  onClick={() => {
                    setOpen(true);
                    if (isMobile) toggleFilter();
                  }}
                >
                  {child.name}
                </NavLink>
              ))}
            </div>
          </div>
          <hr class="solid3"></hr>
        </li>
      );
    } else {
      return (
        <li>
          <NavLink
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
            to={`/user/shop/${item._id}`}
            onClick={() => {
              if (isMobile) toggleFilter();
            }}
            className="category plain"
          >
            {item.name}
          </NavLink>
          <hr class="solid3"></hr>
        </li>
      );
    }
  };

  return generateCategoriesTree(item);
}
export default Category;
