import React, { Component } from "react";
import Categories from "../categories/categories";
import PriceRange from "../price range/priceRange";
import "./filter.css";
function Filter(props) {
  if (props.isOpen == true) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }
  return (
    <div className={props.isOpen ? "modal" : null}>
      <div className={props.isOpen ? "overlay" : null}>
        <div
          className={
            props.isOpen ? "filtermobile filtermobile--open" : "filtermobile"
          }
        >
          <span onClick={props.toggleFilter}>X</span>
          <Categories toggleFilter={props.toggleFilter} />
          <PriceRange
            products={props.products}
            filterPriceProducts={props.filterPriceProducts}
          />
        </div>
      </div>
    </div>
  );
}
export default Filter;
