import React, { Component, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./product.css";
import { useTranslation } from "react-i18next";
import Rating from "@mui/material/Rating";
import shirt from "../../../assets/peakpx.jpg";
import CircularProgress from "@mui/material/CircularProgress";
function ProductCard(props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let tot = 0;
  if (props.reviews.length > 0) {
    for (let rev of props.reviews) {
      tot = tot + rev.stars;
    }
  }

  let stars = tot / props.reviews.length;
  // return (
  //   <div
  //     className="product-card"
  //     onClick={() => navigate(`/user/product/${props.id}`)}
  //   >
  //     <div
  //       className="img-progress"
  //       style={{ display: loading ? "flex" : "none" }}
  //     >
  //       {" "}
  //       <CircularProgress color="inherit" />
  //     </div>
  //     <img
  //       src={shirt}
  //       style={{ display: loading ? "none" : "block" }}
  //       onLoad={() => setLoading(false)}
  //     />
  //     <div className="product-info">
  //       <span className="category">tshirt</span>
  //       <span className="nom">{props.nom}</span>
  //       <div className="price-rating">
  //         <span className="price">15 €</span>
  //         <Rating name="simple-controlled" value="3" readOnly />
  //       </div>
  //     </div>
  //     <button>APPERCU</button>
  //   </div>
  // );
  return (
    <div
      className="product-card"
      onClick={() => navigate(`/user/product/${props.id}`)}
    >
      <div
        className="img-progress"
        style={{ display: loading ? "flex" : "none" }}
      >
        {" "}
        <CircularProgress color="inherit" />
      </div>

      <img
        src={props.pic}
        style={{ display: loading ? "none" : "block" }}
        onLoad={() => setLoading(false)}
      />
      <div className="product-info">
        <span className="category">{props.category}</span>
        <span className="nom">{props.nom}</span>

        <span className="price">{props.prix} €</span>
        <Rating name="simple-controlled" value={stars} readOnly />
      </div>
      <button>{t("view")}</button>
    </div>
  );
}
export default ProductCard;
